import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import router from './routes/route';
import axios from 'axios';
import { useEffect } from 'react';

const App = () => {

	useEffect(() => {
		const fetchGtmData = async () => {
			try {
				const response = await axios.get('https://www.vtprealty.in/gtm/fetchdata.php', {
					headers: {
						Origin: 'https://vtpearthone.com',
					},
				});

				const { data } = response;

				if (data.status === 'success' && Array.isArray(data.data)) {
					const gtmData = data.data.find((row) => row.site_url === 'https://vtpearthone.com');
					if (gtmData) {
						if (gtmData.headgtm && !document.querySelector(`script[src*="googletagmanager.com"]`)) {
							const headElement = document.head;
							headElement.insertAdjacentHTML('afterbegin', gtmData.headgtm);
						}

						if (gtmData.bodygtm && !document.querySelector(`noscript[data-gtm]`)) {
							const bodyElement = document.body;
							const tempElement = document.createElement('div');
							tempElement.innerHTML = gtmData.bodygtm;
							const noscriptElement = tempElement.querySelector('noscript');

							if (noscriptElement) {
								noscriptElement.setAttribute('data-gtm', 'true');
								bodyElement.insertAdjacentHTML('afterbegin', noscriptElement.outerHTML);
							}
						}


						const gtmIdMatch = gtmData.headgtm.match(/GTM-[A-Z0-9]+/);
						if (gtmIdMatch) {
							initializeGTM(gtmIdMatch[0]);
						}
					}
				}
			} catch (error) {
				console.error('Error fetching GTM data:', error);
			}
		};

		const initializeGTM = (gtmId) => {
			if (!window.dataLayer) {
				window.dataLayer = [];
				window.dataLayer.push({ event: 'gtm.js' });

				if (!document.querySelector(`script[src="https://www.googletagmanager.com/gtm.js?id=${gtmId}"]`)) {
					const script = document.createElement('script');
					script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
					script.async = true;
					document.head.appendChild(script);
				}
			}
		};

		fetchGtmData();
	}, []);

	return (
		<Suspense>
			<RouterProvider router={router()} />
		</Suspense>
	)
}

export default App;